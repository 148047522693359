import RuthLogo from '../assets/Logo.png'
import { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";


function Footer() {
  useEffect(() => {
    AOS.init({duration:1200})
  })

  return (

<div class='inset-x-0 bottom-0 bg-black syne'>
<footer class="relative text-white bg-black bottom-0">
  <img src={RuthLogo} alt="" class="absolute inset-[0%] -z-[1] inline-block h-full w-full object-cover" />

  <div class="mx-auto w-full max-w-3xl px-5 py-6 md:px-5 md:py-4 lg:py-5">
    <div class="flex flex-col items-center" data-aos="fade-up">
      <a href="/">
        <img src={RuthLogo} alt="" class="inline-block max-h-24" />
      </a>
      <div class="mt-10 text-center font-semibold">
        <a href="/" class="inline-block px-6 py-2 text-white transition [border-bottom:1px_solid_rgba(0,_0,_0,_0)] hover:text-white hover:[border-bottom:1px_solid_rgb(228,164,189)]">Home</a>
        <a href="/About" class="inline-block px-6 py-2 text-white transition [border-bottom:1px_solid_rgba(0,_0,_0,_0)] hover:text-white hover:[border-bottom:1px_solid_rgb(228,164,189)]">About</a>
        <a href="/Portfolio" class="inline-block px-6 py-2 text-white transition [border-bottom:1px_solid_rgba(0,_0,_0,_0)] hover:text-white hover:[border-bottom:1px_solid_rgb(228,164,189)]">Portfolio</a>
        <a href="/Contact" class="inline-block px-6 py-2 text-white transition [border-bottom:1px_solid_rgba(0,_0,_0,_0)] hover:text-white hover:[border-bottom:1px_solid_rgb(228,164,189)]">Contact Me</a>

      </div>
      <div class="mb-16 mt-10 flex gap-7 px-6 py-2.5">
        <a href="/" class="max-w-[24px] text-gray-900 transition hover:text-white">
          <svg width="40" height="41" viewBox="0 0 40 41" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 20.0176C0 31.0633 8.95431 40.0176 20 40.0176C31.0457 40.0176 40 31.0633 40 20.0176C40 8.97188 31.0457 0.0175781 20 0.0175781C8.95431 0.0175781 0 8.97188 0 20.0176Z" fill="#E4A4BD"></path>
            <path d="M12.5938 13.0019C12.9629 12.9588 13.3255 13.1241 13.5352 13.4309C15.0105 15.5897 16.7826 16.4878 18.7489 16.6921C18.8454 15.8509 19.0724 15.0197 19.4991 14.288C20.125 13.2143 21.143 12.4239 22.5972 12.1318C24.6066 11.7282 26.1366 12.4559 27.0244 13.3471L28.8162 13.0122C29.199 12.9406 29.5884 13.0977 29.8144 13.4148C30.0403 13.7319 30.0617 14.1513 29.8691 14.4897L28.1496 17.5118C28.3064 21.8732 27.0944 24.9169 24.5103 27.0139C23.1395 28.1262 21.178 28.7571 19.0249 28.9519C16.855 29.1482 14.4021 28.9108 11.9638 28.1988C11.536 28.0739 11.2425 27.681 11.2441 27.2354C11.2457 26.7897 11.542 26.3989 11.9706 26.277C13.1968 25.9283 14.1301 25.6268 14.9738 25.1003C13.7753 24.464 12.8923 23.6322 12.2673 22.6839C11.3986 21.3658 11.0765 19.8964 11.0125 18.5711C10.9486 17.2459 11.1401 16.0185 11.3418 15.1327C11.4566 14.6285 11.5907 14.1218 11.7755 13.6379C11.9098 13.2891 12.2246 13.0449 12.5938 13.0019Z" fill="currentColor"></path>
          </svg>
        </a>
        <a href="/" class="max-w-[24px] text-gray-900 transition hover:text-white">
          <svg width="40" height="41" viewBox="0 0 40 41" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 20.0176C0 31.0633 8.9543 40.0176 20 40.0176C31.0457 40.0176 40 31.0633 40 20.0176C40 8.97188 31.0457 0.0175781 20 0.0175781C8.9543 0.0175781 0 8.97188 0 20.0176Z" fill="#E4A4BD"></path>
            <path d="M21.0642 14.2002L21.8087 13.8944C22.4141 13.6533 23.1955 13.409 24.1192 13.5643C26.01 13.8821 27.3145 14.9031 28.0914 16.2573C28.3908 16.7791 28.1489 17.4666 27.5887 17.686C26.6569 18.0509 25.9999 18.9575 25.9999 20.015C25.9999 21.1227 26.7211 22.0647 27.7222 22.3919C28.2405 22.5614 28.5317 23.1367 28.3615 23.6547C27.9201 24.9974 27.2836 26.2363 26.5305 27.2363C25.7871 28.2236 24.879 29.0446 23.868 29.4448C23.2088 29.7058 22.5002 29.6083 21.8227 29.4402L21.4206 29.3332L20.8237 29.1598C20.5528 29.0815 20.2747 29.0133 19.9999 29.0133C19.7251 29.0133 19.447 29.0815 19.1761 29.1598L18.5793 29.3332L18.1772 29.4402C17.4996 29.6083 16.7911 29.7058 16.1319 29.4448C14.8586 28.9408 13.7355 27.7648 12.8874 26.3784C12.0242 24.9673 11.3701 23.2178 11.1034 21.3925C10.8762 19.838 10.9988 18.0935 11.7181 16.6169C12.4589 15.0962 13.8138 13.9117 15.8806 13.5643C16.7204 13.4231 17.4426 13.6121 18.0218 13.8288L18.352 13.9597L18.9357 14.2002C19.3354 14.3568 19.6515 14.449 19.9999 14.449C20.3484 14.449 20.6645 14.3568 21.0642 14.2002ZM19.7677 10.2828C20.744 9.30649 22.2426 9.22214 22.5961 9.5757C22.9497 9.92925 22.8653 11.4278 21.889 12.4041C20.9127 13.3804 19.4141 13.4648 19.0606 13.1112C18.707 12.7577 18.7914 11.2591 19.7677 10.2828Z" fill="currentColor"></path>
          </svg>
        </a>
        <a href="https://github.com/ruthfeezota" class="max-w-[24px] text-gray-900 transition hover:text-white">
          <svg width="40" height="41" viewBox="0 0 40 41" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 20.0176C0 31.0633 8.9543 40.0176 20 40.0176C31.0457 40.0176 40 31.0633 40 20.0176C40 8.97188 31.0457 0.0175781 20 0.0175781C8.9543 0.0175781 0 8.97188 0 20.0176Z" fill="#E4A4BD"></path>
            <path d="M15.0236 9.80887C15.6422 10.0193 16.4075 10.3546 17.1487 10.8544C18.0469 10.6228 19.0061 10.4983 20.0001 10.4983C20.993 10.4983 21.9513 10.6226 22.8488 10.8537C23.5898 10.3541 24.3549 10.019 24.9733 9.8087C25.6702 9.57176 26.6635 9.18702 27.2532 9.84033C27.6536 10.2839 27.7529 11.0283 27.8243 11.5958C27.9041 12.23 27.9227 13.0567 27.7128 13.8763C28.5157 14.9131 29.0001 16.1507 29.0001 17.4983C29.0001 19.5405 27.894 21.3131 26.2569 22.5409C25.5034 23.106 24.6274 23.5656 23.6667 23.8971C23.8811 24.3874 24.0001 24.929 24.0001 25.4983V28.4983C24.0001 29.0506 23.5523 29.4983 23.0001 29.4983H17C16.4478 29.4983 16 29.0506 16 28.4983V27.507C15.0447 27.624 14.2441 27.5205 13.5626 27.2314C12.8508 26.9294 12.3547 26.461 11.9818 26.0135C11.6281 25.5891 11.2425 24.6332 10.6838 24.447C10.1599 24.2723 9.8767 23.706 10.0513 23.1821C10.226 22.6581 10.7923 22.375 11.3163 22.5496C11.982 22.7715 12.4155 23.2517 12.7132 23.6369C13.1923 24.2568 13.5836 25.0678 14.3437 25.3902C14.6563 25.5228 15.1155 25.6108 15.8323 25.512L16.0001 25.4785C16.0028 24.9164 16.1215 24.3817 16.3334 23.8971C15.3727 23.5656 14.4967 23.106 13.7432 22.5409C12.1061 21.3131 11.0001 19.5405 11.0001 17.4983C11.0001 16.1523 11.4833 14.9159 12.2845 13.8799C12.0738 13.0596 12.0922 12.2321 12.1719 11.5973C12.1735 11.5845 12.1751 11.5717 12.1767 11.5589C12.2496 10.9774 12.3355 10.2921 12.7431 9.84044C13.3327 9.18709 14.3268 9.5719 15.0236 9.80887Z" fill="currentColor"></path>
          </svg>
        </a>
        <a href="/" class="max-w-[24px] text-gray-900 transition hover:text-white">
          <svg width="40" height="41" viewBox="0 0 40 41" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 20.0176C0 31.0633 8.95431 40.0176 20 40.0176C31.0457 40.0176 40 31.0633 40 20.0176C40 8.97188 31.0457 0.0175781 20 0.0175781C8.95431 0.0175781 0 8.97188 0 20.0176Z" fill="#E4A4BD"></path>
            <path d="M21.5 29.9059C26.3113 29.1821 30 25.0306 30 20.0176C30 14.4947 25.5228 10.0176 20 10.0176C14.4771 10.0176 10 14.4947 10 20.0176C10 25.0306 13.6887 29.1821 18.5 29.9059V23.0176H17C16.1716 23.0176 15.5 22.346 15.5 21.5176C15.5 20.6892 16.1716 20.0176 17 20.0176H18.5V18.0176C18.5 16.0846 20.067 14.5176 22 14.5176H22.5C23.3284 14.5176 24 15.1891 24 16.0176C24 16.846 23.3284 17.5176 22.5 17.5176H22C21.7239 17.5176 21.5 17.7414 21.5 18.0176V20.0176H23C23.8284 20.0176 24.5 20.6892 24.5 21.5176C24.5 22.346 23.8284 23.0176 23 23.0176H21.5V29.9059Z" fill="currentColor"></path>
          </svg>
        </a>
      </div>
    </div>
  </div>

</footer>
</div>






)
}

export default Footer
